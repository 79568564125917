import type { Config } from "tailwindcss";

export default {
  content: ["./app/**/*.{js,jsx,ts,tsx}"],
  theme: {
    extend: {
      colors: {
        loamGreen: "#4F9A3D",
        loamGreenDark: "#01403A",
        loamBeige1: "#D3C4AF",
        loamBeige2: "#E9E1D7",
        loamBeige3: "#4F40EB",
        white: "#FFFFFF",
        black: "#000000",
      },
      fontFamily: {
        main: "Helvetica",
        secondary: "Arimo",
      },
    },
  },
} satisfies Config;
